
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import Brands from '@/apis/Brands'
// import { CarBrand } from '@/typings/AdminTyping'

@Component({
  name: 'AdminBrands',
  components: {
  }
})

export default class AdminBrands extends Mixins(TableMixin) {
  simpleTable = false
  brandsList = {
    headers: [
      {
        text: '№',
        align: 'start',
        value: 'number'
      },
      {
        text: 'Марка',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Редактировать',
        align: 'start',
        value: 'edit'
      }
    ],
    items: []
  }

  isLoading = true

  created() {
    this.getBrands(0)
  }

  getBrands(brandId: number) {
    Brands.fetchBrands(brandId)
      .then(({ data }) => {
        this.brandsList.items = data.data
        this.isLoading = false
      })
  }

  countCarBrands(number: number): string {
    return stringUtils.pluralForm(number, ['марка', 'марки', 'марок'])
  }

  get search() {
    return this.$store.state.common.searchAdminBrands
  }

  set search(item) {
    this.$store.state.common.searchAdminBrands = item
  }
}
